.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
}


.app__work-portfolio {
  width: 100%;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // align-items: center;

  // @media screen and (max-width: 599px) {
  //     margin-top: 20px;
  // }

  .app__work-item {
    display: flex;
    align-items: center;
    //width: 100%;
     //border: 1px dotted red;

    margin: 3rem 2rem;
    padding: 4rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;

    @media screen and (min-width: 2000px) {
      //width: 470px;
      padding: 4rem;
      border-radius: 0.75rem;
    }

    @media screen and (max-width: 1440px) {
      // width: 100%;
      margin: 3rem 0;
      padding: 4rem;
      flex-direction: column;
    }

    @media screen and (max-width: 1024px) {
      // width: 100%;
      margin: 3rem 0;
      padding: 2rem 1rem;
      flex-direction: column;
    }
  }
}

.app__work-img {
  width: 50%;
  height: 380px;
  overflow: hidden;
  position: relative;
  order: 1;
  // border: 1px dotted red;
  

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    border: 1px solid #e7e7e7;
    object-fit: cover;
    display: block;

    @media screen and (max-width: 1440px) {
      object-fit: contain;
      height: auto;
  }
  }

  @media screen and (max-width: 1440px) {
    width: 100%;
      order: 0;
      height: auto;
  }
  

  @media screen and (min-width: 2000px) {
    width: 50%;
    aspect-ratio: 5/9;
    //height: 50%;
    height: 900px;
  }
}

.app__work-content {
  padding: 4rem 4rem 4rem 0;
  width: 50%;
  position: relative;
  order: 0;
  // border: 1px dotted red;

  @media screen and (max-width: 1024px) {
    width: 100%;
      padding: 0.5rem;
      order: 1;
    }
  @media screen and (max-width: 1440px) {
    width: 100%;
      // padding: 0.5rem;
      order: 1;
    }

  h4 {
    margin-top: 1rem;
    line-height: 1.5;

    @media screen and (min-width: 2000px) {
      margin-top: 3rem;
    }
  }

  .button {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    padding: 1rem 3rem;
    border-radius: 10px;
    border: none;
    background-color: var(--secondary-color);

    font-weight: 500;
    color: var(--white-color);
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: #2430af;
    }

    @media screen and (max-width: 599px) {
      font-size: 0.7rem;
       padding: 1rem 2rem;
    }

  }

}

.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}

.links-wrapper {
  width: 100%;
  display: flex;

  @media screen and (max-width: 599px) {
       justify-content: space-around;
       padding: 0 1rem;
    }
}

.stack {
  @media screen and (max-width: 599px) {
       font-size: 0.9rem;
    }

  @media screen and (max-width: 1440px) {
       font-size: 0.9rem;
    }
}